<template>
  <div>
    <t-row v-if="isCampaign && !previewMode">
      <t-col class="mb-2">
        <div class="d-flex-center mb-4 mx-1">
          <template v-if="campaignPlanId">
            <b-btn variant="ghost-primary"
                   @click="createNewNamedGeo()">
              <fluency-icon type="upload" />
              Save as {{verbiage.geoTargetItem}}
            </b-btn>
            <template v-if="availableAccountGeoOptions && availableAccountGeoOptions.length > 0">-or-</template>
          </template>
          <template v-if="availableAccountGeoOptions && availableAccountGeoOptions.length > 0">
            <basic-mad-lib-input :value="namedGeoSelection"
                                 :type-ahead-options="availableAccountGeoOptions"
                                 :placeholder="`Select a ${verbiage.geoTargetItem}`"
                                 class="mx-4"
                                 @keyup.esc.stop.cancel="keyupCancel($event)"
                                 @input="selectNamedGeo($event)" />
            -or-
            <span class="mx-3">make campaign specific edits below</span>
          </template>
        </div>
      </t-col>
    </t-row>
    <div class="row">
      <t-alert show v-if="numberOfTargetsExceedsMax" variant="info" class="ml-5">
        There are too many Geo Targets to display here. To see all of them, please <b-button variant="link" class="p-0" @click="downloadGeoTargetCSV">download this file</b-button>
      </t-alert>

      <div v-if="!previewMode" class="col-5">
        <t-alert show v-if="accountPlan && !accountPlan.address1" variant="warning" class="py-2 px-3 mb-3">Warning: This account has no street address specified.</t-alert>
        <b-card no-body class="mt-0">
          <b-card-header>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <slot name="header"></slot>
              </div>
              <b-button variant="ghost-primary" size="sm" @click="bulkEditing = !bulkEditing">
                <fluency-icon :type="bulkEditing ? 'close' : 'add'" class="mr-2" />
                {{ bulkEditing? 'Cancel' : 'Add in Bulk' }}
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <template v-if="!bulkEditing">
              <div class="d-flex my-2">
                <b-button-group class="mr-4" v-if="this.advertisingChannelId !== 25">
                  <b-button :variant="(selectedLocationOrRadius === 'location') ? 'secondary' : 'outline-secondary'"
                            @click="selectedLocationOrRadius = 'location'">Location</b-button>
                  <!-- temporarily hide radius for amazon until it is supported in amazon-->
                  <b-button v-if="advertisingChannelId !== 5"
                            :variant="(selectedLocationOrRadius === 'radius') ? 'secondary' : 'outline-secondary'"
                            @click="selectedLocationOrRadius = 'radius'">Radius</b-button>
                </b-button-group>

                <!--      Location Input-->
                <template v-if="selectedLocationOrRadius === 'location'">
                  <b-input-group>
                    <b-form-input :value="searchString"
                                  placeholder="Enter a location to target or exclude"
                                  @input="searchString = $event; autoComplete()"/>
                    <b-input-group-append v-if="searchString.length > 0">
                      <b-btn @click="searchString = '';autoComplete()" style="border-radius: 0 .25rem .25rem 0">x</b-btn>
                    </b-input-group-append>

                    <!--      Auto Complete Matches-->
                    <div v-if="matches.length > 0" class="p-2 hover rounded" style="position:absolute;z-index:2000;width: 400px;border:1px solid #ccc;top:32px;background-color:white;max-height: 23rem;overflow-y: auto;">
                      <div v-for="match in matches"
                           :key="match.googleGeoId"
                           class="d-flex mt-1 geoLocation">
                        <div class="d-flex py-2 w-100 justify-content-end">
                          <div class="mr-auto ml-3">
                            {{match.canonicalName}}
                            <small class="text-muted">({{match.targetType}})</small>
                          </div>
                          <t-button-linke @click="addToIncludedList(match), matches = [], searchString = ''" class="white-space-no-wrap mr-3">
                            Target
                          </t-button-linke>
                          <t-button-link v-if="hasNegativeGeoTargetCapability"
                                  class="white-space-no-wrap mr-3"
                                  @click="addToExcludedList(match), matches = [], searchString = ''">
                            Exclude
                          </t-button-link>
                        </div>
                      </div>
                    </div>
                  </b-input-group>
                </template>

                <!--      Radius Input-->
                <template v-if="selectedLocationOrRadius === 'radius'">
                  <b-input-group>
                    <b-form-input :value="radiusInput"
                                  placeholder="Lat, Long"
                                  @input="radiusInput = $event; validateCoordinates()"/>
                    <b-input-group-append>
                      <b-form-input :plain="true" v-model="radius" class="pl-3 pr-3" style="width:40px; border-radius: 0; border-right: 0" value="20"/>
                      <b-form-select :plain="true" v-model="distanceUnits" class="pl-3 pr-3" style="width:60px; border-radius: 0 .25rem .25rem 0" :options="['mi', 'km']" @change="setDistanceUnit($event)"/>
                    </b-input-group-append>
                  </b-input-group>
                </template>
              </div>

              <div v-if="selectedLocationOrRadius === 'radius'" class="d-flex justify-content-end">
                <b-button disabled v-if="!validatedCoordinates" size="sm" variant="link" class="py-0 mb-2">Enter coordinates or click on the map to define a center point</b-button>
                <b-button v-else size="sm" variant="link" class="py-0 mb-2" @click="coordinateInput()"><fluency-icon type="add" class="mr-2" />Add Target</b-button>
              </div>

            </template>

            <!--      Bulk Edit-->
            <template v-else-if="bulkEditing">
              <textarea rows="4" class="form-control" v-model="bulkGeoTargets" />
              <div>
                <b-btn variant="link" @click="bulkUpdateGeoTargets('include')">
                  Target
                </b-btn>
                <b-btn v-if="hasNegativeGeoTargetCapability"
                       variant="link" @click="bulkUpdateGeoTargets('exclude')">
                  Exclude
                </b-btn>
              </div>
            </template>

            <div class="my-4"></div>

              <!-- targeted location-->
              <collapsible-card visible bodyClasses="p-0" compactHeader class="location-card mt-0">
                <template v-slot:header="slotProps">
                  <h6 class="mb-0 py-2 d-inline-block" style="width: 63%; margin-left: 3%">Targeted Locations</h6>
                  <h6 v-if="slotProps.expanded && hasGeoBidModifierCapability" class="mb-0 py-2 d-inline-block" style="width: 30%">Geo Bid Modifier</h6>
                </template>
                <div class="location-box">
                  <div v-for="(includedGeoTarget, index) in geoTargetDTO.includedGeoCodeTargets?.slice(0, Math.min(maxTargets, geoTargetDTO.includedGeoCodeTargets.length)) ?? []"
                       :key="'includedGeoTarget'+index"
                       class="d-flex py-2">
                    <b-button variant="link"
                              class="p-0 text-danger"
                              style="width: 10%"
                              v-p-tooltip.top="'Remove Location'"
                              @click="removeIncludedGeoTarget(index)">
                      <fluency-icon type="delete" />
                    </b-button>
                    <span style="width: 60%">
                      {{includedGeoTarget.googleCanonicalName}}
                      <template v-if="missingPolygonData.includes(includedGeoTarget.googleGeoId)">(not pictured)</template>
                    </span>
                  <popover-cancel-save v-if="hasGeoBidModifierCapability"
                                       :use-table-action-container="false"
                                       :value="includedGeoTarget.bidModifier"
                                       style="width: 30%"
                                       @save="saveGeoBidModifier($event, index, 'includedGeoCodeTargets')">
                    <template #trigger-content>
                        <span class="cursor-pointer">
                          <span v-if="includedGeoTarget.bidModifier && includedGeoTarget.bidModifier !== 1">
                            {{geoBidModifier(includedGeoTarget.bidModifier)}}
                            <fluency-icon type="edit" class="edit-icon text-success" />
                          </span>
                          <span v-else v-p-tooltip.top="'Add geo bid modifier'">
                            <fluency-icon type="add" class="text-secondary"/>
                          </span>
                        </span>
                    </template>
                    <template #edit="{value, update}">
                      <bid-adjustment-input :value="value" @input="update(updateModifier($event, index, 'includedGeoCodeTargets'))"/>
                    </template>
                  </popover-cancel-save>
                </div>

                <div v-for="(includedRadiusTarget, index) in geoTargetDTO.includedRadiusTargets?.slice(0, Math.min(maxTargets, geoTargetDTO.includedRadiusTargets.length)) ?? []"
                     :key="'includedRadius'+index"
                     class="d-flex py-2"
                     :class="{'selected-radius': selectedRadiusIndex === index, 'active-underspend': includedRadiusTarget.info === 'UNDERSPEND_MITIGATION'}">

                  <b-button variant="link"
                            class="text-danger p-0"
                            :class="{'invisible': includedRadiusTarget.info === 'UNDERSPEND_MITIGATION'}"
                            @click="removeIncludedRadiusTarget(index)"
                            style="width: 10%">
                    <fluency-icon type="delete" v-p-tooltip.top="'Remove Location'" />
                  </b-button>

                  <div v-if="includedRadiusTarget.address" style="width: 60%"
                       @click="selectedRadius({radiusIndex: (selectedRadiusIndex === index)?-1:index, radiusType: 'includedRadiusTargets'})">
                    {{includedRadiusTarget.radius}}{{(includedRadiusTarget.radiusUnit === 'KILOMETERS') ? 'km' : 'mi' }}
                    around {{parseAddressString(includedRadiusTarget.address)}}
                  </div>
                  <div v-else-if="includedRadiusTarget.itemCentroidLat" style="width: 60%"
                       @click="selectedRadius({radiusIndex: (selectedRadiusIndex === index)?-1:index, radiusType: 'includedRadiusTargets'})">
                    {{includedRadiusTarget.radius}}{{(includedRadiusTarget.radiusUnit === 'KILOMETERS') ? 'km' : 'mi' }}
                    around ({{includedRadiusTarget.itemCentroidLat}}, {{includedRadiusTarget.itemCentroidLng}})
                  </div>

                  <popover-cancel-save v-if="hasGeoBidModifierCapability"
                                       :value="includedRadiusTarget.bidModifier"
                                       :use-table-action-container="false"
                                       @save="saveGeoBidModifier($event, index, 'includedRadiusTargets')"
                                       style="width: 30%">
                    <template #trigger-content>
                        <span class="cursor-pointer" v-if="includedRadiusTarget.bidModifier && includedRadiusTarget.bidModifier !== 1">
                          {{geoBidModifier(includedRadiusTarget.bidModifier)}}
                          <fluency-icon type="edit" class="edit-icon text-success" />
                        </span>
                      <span v-else v-p-tooltip.top="'Add geo bid modifier'">
                          <fluency-icon type="add" class="cursor-pointer text-secondary"/>
                        </span>
                    </template>
                    <template #edit="{value, update}">
                      <bid-adjustment-input :value="value" @input="update(updateModifier($event, index, 'includedRadiusTargets'))"/>
                    </template>
                  </popover-cancel-save>

                </div>
              </div>
            </collapsible-card>

            <div v-if="ineligibleChannelsForBiddingList" class="small text-muted px-3 pb-3">
              <b>Note:</b> Geo Bid Modifiers not available for {{ineligibleChannelsForBiddingList}} campaigns.
            </div>

            <!--          excluded locations-->
            <collapsible-card v-if="hasNegativeGeoTargetCapability"
                              bodyClasses="p-0"
                              class="location-card" compactHeader>
              <template #header>
                <h6 class="mb-0 py-2 w-100" style="margin-left: 3%">Excluded Locations</h6>
              </template>
              <div class="location-box">
                <div v-for="(excludedGeoTarget, index) in geoTargetDTO.excludedGeoCodeTargets?.slice(0, Math.min(maxTargets, geoTargetDTO.excludedGeoCodeTargets.length)) ?? []"
                     :key="'excludedGeoTarget'+index"
                     class="d-flex py-2">
                  <b-button variant="link"
                            class="p-0 text-danger"
                            style="width: 10%"
                            @click="removeExcludedGeoTarget(index)">
                    <fluency-icon type="delete" v-p-tooltip.top="'Remove Location'" />
                  </b-button>

                  <div style="width: 90%">{{excludedGeoTarget.googleCanonicalName}}
                    <template v-if="missingPolygonData.includes(excludedGeoTarget.googleGeoId)">(not pictured)</template>
                  </div>
                </div>

                <div v-for="(excludedRadiusTarget, index) in geoTargetDTO.excludedRadiusTargets?.slice(0, Math.min(maxTargets, geoTargetDTO.excludedRadiusTargets.length)) ?? []"
                     :key="'excludedRadius'+index"
                     class="d-flex py-2"
                     :class="{'selected-radius': selectedRadiusIndex === index, 'active-underspend': excludedRadiusTarget.info === 'UNDERSPEND_MITIGATION'}">

                  <b-button variant="link"
                            class="text-danger p-0"
                            :class="{'invisible': excludedRadiusTarget.info === 'UNDERSPEND_MITIGATION'}"
                            @click="removeExcludedRadiusTarget(index)"
                            style="width: 10%">
                    <fluency-icon type="delete" v-p-tooltip.top="'Remove Location'" />
                  </b-button>
                  <div v-if="excludedRadiusTarget.address" style="width: 60%"
                       @click="selectedRadius({radiusIndex: (selectedRadiusIndex === index)?-1:index, radiusType: 'excludedRadiusTargets'})">
                    {{excludedRadiusTarget.radius}}{{(excludedRadiusTarget.radiusUnit === 'KILOMETERS') ? 'km' : 'mi' }}
                    around {{parseAddressString(excludedRadiusTarget.address)}}
                  </div>
                  <div v-else-if="excludedRadiusTarget.itemCentroidLat" style="width: 60%"
                       @click="selectedRadius({radiusIndex: (selectedRadiusIndex === index)?-1:index, radiusType: 'excludedRadiusTargets'})">
                    {{excludedRadiusTarget.radius}}{{(excludedRadiusTarget.radiusUnit === 'KILOMETERS') ? 'km' : 'mi' }}
                    around ({{excludedRadiusTarget.itemCentroidLat}}, {{excludedRadiusTarget.itemCentroidLng}})
                  </div>
                </div>
              </div>
            </collapsible-card>

            <div v-if="ineligibleChannelsForExclusionsList" class="small text-muted px-3 pb-3">
              <b>Note:</b> Excluded Locations are not available for {{ineligibleChannelsForExclusionsList}} campaigns.
            </div>
          </b-card-body>
        </b-card>
      </div>

<!--      TODO Add message indicating too many. With option to download-->

      <div :class="previewMode ? 'col-12' : 'col-7 pl-0'">

        <div v-if="!previewMode" class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex">
            <b-button-group>
              <b-button v-for="(btn, idx) in mapToolbarButtons"
                        :key="idx"
                        :variant="(btn.state) ? btn.activeVariant : 'outline-secondary'"
                        class="white-space-no-wrap"
                        @click="btn.state = !btn.state">
                {{ btn.caption }}
              </b-button>
            </b-button-group>
          </div>

          <div v-if="selectedRadiusIndex !== -1 && geoTargetDTO[selectedRadiusType][selectedRadiusIndex]"
               class="px-2 py-1" >
            <span>Editing Radius:</span>
            <b-form-input v-model="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].radius" class="d-inline p-2" size="sm" style="width:4em;" type="text" @change="updateRadiusCircle('radius')"/>
            {{(geoTargetDTO[selectedRadiusType][selectedRadiusIndex].radiusUnit === 'KILOMETERS') ? 'km' : 'mi'}}
            <template v-if="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].itemCentroidLat">
              around Lat: <b-form-input v-model="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].itemCentroidLat" class="d-inline p-2" size="sm" style="width:6em;" type="text" @change="updateRadiusCircle('itemCentroidLat')"/>
              Long: <b-form-input v-model="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].itemCentroidLng" class="d-inline p-2" size="sm" style="width:6em;" type="text" @change="updateRadiusCircle('itemCentroidLng')"/>
              <!-- around ({{geoTargetDTO[selectedRadiusType][selectedRadiusIndex].itemCentroidLat}}, {{geoTargetDTO[selectedRadiusType][selectedRadiusIndex].itemCentroidLng}}) -->
            </template>
            <template v-else>
              around ({{geoTargetDTO[selectedRadiusType][selectedRadiusIndex].address}})
            </template>
              <b-form-radio-group
                v-if="advertisingChannelId === 2"
                :checked="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].excluded"
                class="btn-group-toggle-radio p-0"
                size="sm"
                button-variant="muted-active-outline"
                buttons
                @input="geoTargetDTO[selectedRadiusType][selectedRadiusIndex].excluded = $event; processExcludeInput($event)">
                <b-form-radio class="px-2" :value="false">Included</b-form-radio>
                <b-form-radio class="px-2" :value="true">Excluded</b-form-radio>
              </b-form-radio-group>
          </div>
        </div>
        <div v-if="hasTargetingAdjustmentsApplied" class="d-flex justify-content-between align-items-center my-2">
          <div class="white-space-no-wrap">
            <span class="targeting-legend text-muted" v-p-tooltip.top="'The radii highlighted in yellow are expanded from their original size due to active Underspend Mitigation'">Active Underspend</span>
          </div>
        </div>

        <slot name="preview-header" />

        <google-map v-if="accountPlan"
                    :locations="geoTargetDTO"
                    :hide-pin="!mapToolbarButtons[1].state"
                    map-name="Campaign Map"
                    :zips="includedZips"
                    :excluded-zips="excludedZips"
                    :radius="geoTargetDTO.includedRadiusTargets"
                    :account="accountPlan"
                    :advertising-channel-id="advertisingChannelId"
                    @click="mapClick"
                    :height="350"
                    :draggable-radius="mapToolbarButtons[0].state"
                    @circles="processRadiusDrag($event)"
                    @select="selectedRadius($event)"
                    :update-radius="updateRadius"
                    :included-radius-variant="hasTargetingAdjustmentsApplied ? 'warning' : 'success'"
                    @missingPolygons="missingPolygonData = $event"
                    disableScrollZoom
        />
        <slot name="under-map"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import PopoverCancelSave from '@/components/common/popoverCancelSave'
import BidAdjustmentInput from 'core-ui/components/inputs/bidAdjustment'
import GoogleMap from 'core-ui/components/common/googleMap'
import CollapsibleCard from '@/components/common/widgets/collapsibleCard'
import {cloneDeep as _cloneDeep} from 'lodash'
import basicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import Papa from 'papaparse'

export default {
  name: 'geoTargetEditor',
  components: { basicMadLibInput, CollapsibleCard, GoogleMap, BidAdjustmentInput, PopoverCancelSave },
  props: {
    value: { // geoTargetDTO
      type: Object
    },
    accountPlan: { // passed to google-map
      type: Object
    },
    budgetPlanDto: { // used to show if underspend targeting adjustments are applied. used on campaign plans. not currently used on ad groups
      type: Object,
      required: false
    },
    capabilities: { // pass in the capabilities object from the Account, Campaign, or AdGroup
      type: Object,
      required: false
    },
    advertisingChannelId: { // used in the geoBrowse endpoint.
      type: [Number, String],
      required: false
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    availableAccountGeoOptions: {
      type: Array,
      default: null
    },
    isCampaign: {
      type: Boolean,
      default: false
    },
    isAccount: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedLocationOrRadius: 'location',
      bulkEditing: false,
      searchString: '',
      autoCompleteTimeout: null,
      radius: '20',
      distanceUnits: 'mi',
      bulkGeoTargets: '',
      geoTargetDTOSkeleton: {
        geoTarget: {},
        includedRadiusTargets: [],
        excludedGeoCodeTargets: [],
        includedGeoCodeTargets: [],
        excludedRadiusTargets: []
      },
      matches: [],
      tmpGBMSavedVal: {
        includedGeoCodeTargets: [],
        includedRadiusTargets: []
      },
      selectedRadiusIndex: -1,
      selectedRadiusType: '',
      missingPolygonData: [],
      mapToolbarButtons: [
        { caption: 'Edit Mode', state: false, activeVariant: 'warning' },
        { caption: 'Address Pin', state: true, activeVariant: 'info' }
      ],
      updateRadius: {},
      radiusInput: '',
      validatedCoordinates: '',
      createFlowGeoAssetPlaceholderId: null,
      verbiage: {
        geoTargetHeading: 'Geo Targeting',
        geoTargetLibrary: 'Geo Targeting Library',
        geoTargetItem: 'Named Geo Target'
      },
      determineEligibility: null,
      namedGeoSelection: '',
      maxTargets: 250
    }
  },
  watch: {
    accountPlan: {
      handler (val, old) {
        if (val && (!old || val.accountPlanId !== old.accountPlanId)) {
          // update distance units to user preferred
          const units = this.$store.getters.setting('distanceUnit')
          if (units) {
            this.distanceUnits = units
          } else {
            this.distanceUnits = val.country === 'US' ? 'mi' : 'km'
          }
          // disable Pin if there is no address info
          if (!val.city && !val.address1) {
            this.mapToolbarButtons[1].state = false
          }

          this.checkForExclusions()
        }
      },
      immediate: true
    }
  },
  computed: {
    geoTargetDTO: {
      get () {
        return this.value || this.geoTargetDTOSkeleton
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    hasTargetingAdjustmentsApplied () {
      return this.budgetPlanDto?.budgetUnderspendStatus?.hasTargetingAdjustmentsApplied
    },
    includedZips () {
      return this.getZips()
    },
    excludedZips () {
      return this.getExcludedZips()
    },
    hasNegativeGeoTargetCapability () {
      if (this.isAccount) {
        return true // hardcoded for Account level `Named Geo Targets`
      }

      return this.capabilities?.NegativeGeoTargets ?? false
    },
    hasGeoBidModifierCapability () {
      if (this.isAccount) {
        return true // hardcoded for Account level `Named Geo Targets`
      }

      return this.capabilities?.GeoTargetBidModifiers ?? false
    },
    campaignPlanId () {
      if (this.$route.query?.settingsItemType === 'campaign' && this.$route.query?.settingsItemId) {
        return parseInt(this.$route.query?.settingsItemId)
      }

      if (this.$route.query?.id) {
        return parseInt(this.$route.query?.id)
      }

      return null
    },
    advertisingChannels () {
      return this.$store.getters.advertisingChannels
    },
    ineligibleChannelsForBiddingList () {
      return this.buildIneligibleList('ineligibleForBidding')
    },
    ineligibleChannelsForExclusionsList () {
      return this.buildIneligibleList('ineligibleForExclusions')
    },
    numberOfTargetsExceedsMax () {
      return this.geoTargetDTO.includedGeoCodeTargets?.length > this.maxTargets ||
        this.geoTargetDTO.includedRadiusTargets?.length > this.maxTargets ||
        this.geoTargetDTO.excludedGeoCodeTargets?.length > this.maxTargets ||
        this.geoTargetDTO.excludedRadiusTargets?.length > this.maxTargets
    }
  },
  methods: {
    geoBidModifier: function (value) {
      if (value !== 0) {
        const val = (value - 1) * 100
        return ((val > 0) ? '+' : '') + Math.round(val) + '%'
      } else {
        return '-'
      }
    },
    parseAddressString (address) {
      let addr = address
      // there are a few different types of data that comes in
      if (address?.startsWith('Address{')) {
        if (address.includes('"')) {
          addr = addr.replace('Address{', '{')
          addr = JSON.parse(addr)
          addr = `${addr?.streetAddress?.replaceAll('null', '')?.trim() || ''} ${addr.cityName || ''} ${addr.state || ''} ${addr.postalCode || ''} ${addr.countryCode || ''}`
        } else if (address.startsWith('Address{streetAddress=')) {
          addr = addr.replace('Address{streetAddress=', '').replace('}', '')
        }
      }
      return addr
    },
    autoComplete () {
      clearTimeout(this.autoCompleteTimeout)
      if (this.searchString !== '') {
        this.autoCompleteTimeout = setTimeout(this.search, 500)
      } else {
        this.matches = []
      }
    },
    validateCoordinates () {
      if (/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(this.radiusInput)) {
        this.validatedCoordinates = this.radiusInput
      } else {
        this.validatedCoordinates = ''
      }
    },
    coordinateInput () {
      const split = this.validatedCoordinates.split(',')
      this.addToIncludedRadiusList({
        latitude: parseFloat(split[0].trim()),
        longitude: parseFloat(split[1].trim())
      })
      this.radiusInput = ''
      this.validatedCoordinates = ''
    },
    async search () {
      const response = await this.$res.fetch.geoBrowse({ searchPhrase: this.searchString, advertisingChannelId: this.advertisingChannelId })
      if (response) {
         // take top 50 so everything doesn't lock up
        this.matches = Object.values(response).reduce((accu, curr) => [...accu, ...curr], []).slice(0, 50)
      }
    },
    async bulkUpdateGeoTargets (type) {
      const targets = this.bulkGeoTargets.split('\n')
      for (let i = 0; i < targets.length; i++) {
        if (!targets[i]) continue
        const response = await this.$res.fetch.geoBrowse({ searchPhrase: targets[i], advertisingChannelId: this.advertisingChannelId, accountPlanId: type === 'exclude' ? null : this.accountPlan.accountPlanId })

        if (response) {
          const matches = Object.keys(response).flatMap(geoKey => response[geoKey])
          if (matches[0]) {
            const geo = matches[0]
            if (geo.radius) {
              if (type === 'exclude') {
                this.addToExcludedRadiusList(geo)
              } else {
                this.addToIncludedRadiusList(geo)
              }
            } else {
              if (type === 'exclude') {
                this.addToExcludedList(geo)
              } else {
                this.addToIncludedList(geo)
              }
            }
          } else {
            this.$toast('Could not find match for "' + targets[i] + '"', 'danger')
          }
        } else {
          this.$toast('No server response. Please try again or contact Fluency', 'danger')
        }
      }
    },
    addToIncludedList (geo) {
      if (this.selectedLocationOrRadius === 'location') {
        if (!this.geoTargetDTO.includedGeoCodeTargets) {
          this.geoTargetDTO.includedGeoCodeTargets = []
        }

        if (this.geoTargetDTO.includedGeoCodeTargets.findIndex(target => target.googleGeoId === geo.googleGeoId) === -1) {
          const geoTarget = {
            googleCanonicalName: geo.canonicalName,
            googleGeoId: geo.googleGeoId
          }

          this.geoTargetDTO.includedGeoCodeTargets.push(geoTarget)
          this.emitGeoTargetUpdate()
        }
      } else {
        this.addToIncludedRadiusList(geo)
      }
    },
    getIncludedGeoTargets () {
      const geos = this.geoTargetDTO.geoTarget?.includedGeoCodeTargets
        ?.filter(geo => geo.geoTargetId === this.geoTargetDTO.geoTarget.geoTargetId) ?? []

      return geos
    },
    getExcludedGeoTargets () {
      const geos = this.geoTargetDTO.geoTarget?.excludedGeoCodeTargets
        ?.filter(geo => geo.geoTargetId === this.geoTargetDTO.geoTarget.geoTargetId) ?? []

      return geos
    },
    addToExcludedList (geo) {
      if (this.selectedLocationOrRadius === 'location') {
        if (!this.geoTargetDTO.excludedGeoCodeTargets) {
          this.geoTargetDTO.excludedGeoCodeTargets = []
        }
        if (this.geoTargetDTO.excludedGeoCodeTargets.findIndex(target => target.googleGeoId === geo.googleGeoId) === -1) {
          const geoTarget = {
            googleCanonicalName: geo.canonicalName,
            googleGeoId: geo.googleGeoId
          }
          this.geoTargetDTO.excludedGeoCodeTargets.push(geoTarget)
          this.emitGeoTargetUpdate()
        }
      } else {
        this.addToExcludedRadiusList(geo)
      }
    },
    addToIncludedRadiusList (geo) {
      if (!this.geoTargetDTO.includedRadiusTargets) {
        this.geoTargetDTO.includedRadiusTargets = []
      }

      let geoTarget = {}
      geoTarget = {
        itemCentroidLat: geo.latitude,
        itemCentroidLng: geo.longitude,
        radius: parseInt(this.radius, 10),
        radiusUnit: (this.distanceUnits === 'mi') ? 'MILES' : 'KILOMETERS',
        excluded: false
      }

      this.geoTargetDTO.includedRadiusTargets.push(geoTarget)
      this.emitGeoTargetUpdate()
    },
    addToExcludedRadiusList (geo) {
      if (!this.geoTargetDTO.excludedRadiusTargets) {
        this.geoTargetDTO.excludedRadiusTargets = []
      }

      let geoTarget = {}
      geoTarget = {
        itemCentroidLat: geo.latitude,
        itemCentroidLng: geo.longitude,
        radius: parseInt(this.radius, 10),
        radiusUnit: (this.distanceUnits === 'mi') ? 'MILES' : 'KILOMETERS',
        excluded: true
      }

      this.geoTargetDTO.excludedRadiusTargets.push(geoTarget)
      this.emitGeoTargetUpdate()
    },
    emitGeoTargetUpdate () {
      this.$emit('input', this.geoTargetDTO)
    },
    saveGeoBidModifier (event, index, type) {
      const val = this.tmpGBMSavedVal[type][index]
      if (val < 0.09 || val > 10 || isNaN(val)) {
        this.$toast('Please enter a geo bid modifier between -90% and 900%', 'danger')
      } else {
        this.geoTargetDTO[type][index].bidModifier = val
        this.$nextTick(() => {
          this.emitGeoTargetUpdate()
        })
      }
    },
    processExcludeInput (excluded) {
      if (excluded) {
        this.geoTargetDTO.excludedRadiusTargets.push(this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex])
        this.geoTargetDTO.includedRadiusTargets.splice(this.selectedRadiusIndex, 1)
        this.selectedRadiusIndex = -1
        this.emitGeoTargetUpdate()
      } else {
        this.geoTargetDTO.includedRadiusTargets.push(this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex])
        this.geoTargetDTO.excludedRadiusTargets.splice(this.selectedRadiusIndex, 1)
        this.selectedRadiusIndex = -1
        this.emitGeoTargetUpdate()
      }
    },
    updateModifier (event, index, type) {
      this.tmpGBMSavedVal[type][index] = event
      return event
    },
    removeIncludedGeoTarget (index) {
      if (this.geoTargetDTO.includedGeoCodeTargets[index].info === 'UNDERSPEND_MITIGATION') {
        return
      }
      this.selectedRadiusIndex = -1
      this.geoTargetDTO.includedGeoCodeTargets.splice(index, 1)
      this.emitGeoTargetUpdate()
    },
    removeIncludedRadiusTarget (index) {
      this.selectedRadiusIndex = -1
      this.geoTargetDTO.includedRadiusTargets.splice(index, 1)
      this.emitGeoTargetUpdate()
    },
    removeExcludedRadiusTarget (index) {
      this.selectedRadiusIndex = -1
      this.geoTargetDTO.excludedRadiusTargets.splice(index, 1)
      this.emitGeoTargetUpdate()
    },
    removeExcludedGeoTarget (index) {
      this.selectedRadiusIndex = -1
      this.geoTargetDTO.excludedGeoCodeTargets.splice(index, 1)
      this.emitGeoTargetUpdate()
    },
    selectedRadius (radius) {
      this.selectedRadiusIndex = radius.radiusIndex
      this.selectedRadiusType = radius.radiusType
    },
    updateRadiusCircle (keyToUpdate) {
      const newRadius = {
        radiusIndex: this.selectedRadiusIndex,
        radiusType: this.selectedRadiusType?.startsWith('exclude') ? 'excludedRadiusCircles' : 'includedRadiusCircles',
        radius: parseFloat(this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex].radius)
      }
      if (this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex].itemCentroidLat) {
        newRadius.itemCentroidLat = parseFloat(this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex].itemCentroidLat)
        newRadius.itemCentroidLng = parseFloat(this.geoTargetDTO[this.selectedRadiusType][this.selectedRadiusIndex].itemCentroidLng)
      }
      this.updateRadius = newRadius
    },
    getZips () {
      const geoTargets = this.getIncludedGeoTargets()

      if (geoTargets.length > 0 && geoTargets[0].googleCanonicalName) {
        return geoTargets.map((x) => {
          const name = x.googleCanonicalName
          return name.slice(0, name.indexOf(','))
        })
      }

      return []
    },
    getExcludedZips () {
      const geoTargets = this.getExcludedGeoTargets()
      let excludedZips = []

      if (geoTargets.length > 0 && geoTargets[0].googleCanonicalName) {
        excludedZips = geoTargets.map((x) => {
          const name = x.googleCanonicalName
          return name.slice(0, name.indexOf(','))
        })
        return excludedZips
      }

      return []
    },
    mapClick (event) {
      // if you are selecting a radius drop that on the map
      if (this.selectedLocationOrRadius === 'radius') {
        this.addToIncludedRadiusList({
          latitude: Math.round(event.latLng.lat() * 10000) / 10000,
          longitude: Math.round(event.latLng.lng() * 10000) / 10000
        })
      }
    },
    processRadiusDrag (circle) {
      if (!circle) return
      const currentCircle = this.geoTargetDTO[circle.radiusType][circle.radiusIndex]
      const unitToMeters = (currentCircle.radiusUnit === 'KILOMETERS') ? 1000 : 1609.34
      if (currentCircle) {
        currentCircle.itemCentroidLat = Math.round(circle.center.lat() * 10000) / 10000
        currentCircle.itemCentroidLng = Math.round(circle.center.lng() * 10000) / 10000
        currentCircle.radius = Math.round(circle.radius * 10000 / unitToMeters) / 10000
        this.emitGeoTargetUpdate()
      }
    },
    setDistanceUnit (unit) {
      this.$store.commit('setting', { key: 'distanceUnit', value: unit })
    },
    async createNewNamedGeo () {
      const conf = await this.$prompt({
        title: `Create a new ${this.verbiage.geoTargetItem}?`,
        'ok-title': `Create this ${this.verbiage.geoTargetItem}`,
        'cancel-title': 'Cancel',
        'body-class': 'overflow-visible',
        formColumns: 2,
        forms: [
          {
            name: 'name',
            required: true,
            label: 'Name',
            description: `Provide a name for your new ${this.verbiage.geoTargetItem}.`,
            inputProps: {
              fillWidth: true
            }
          },
          {
            type: 'SELECT',
            name: 'makeDefault',
            required: true,
            label: 'Make Default',
            description: `Do you want to make this the default ${this.verbiage.geoTargetItem} for Campaigns to use?`,
            default: 'NO',
            options: [
              { text: 'Yes, Make Default', value: 'YES' },
              { text: 'No', value: 'NO' }
            ]
          }
        ]
      })

      if (!conf) {
        return
      }

      const geoCopy = _cloneDeep(this.geoTargetDTO)

      if (conf) {
        geoCopy.geoTarget.name = conf.name

        const makeDefault = conf.makeDefault === 'YES'

        const response = await this.$res.account.geoAssetCreateAssetFromCampaignGeo(this.accountPlan.accountPlanId, makeDefault, geoCopy)

        if (response) {
          this.$toast(`Successfully saved to your ${this.verbiage.geoTargetLibrary}`, 'success')
          this.$emit('refreshGeoList')
          this.$emit('linkToGeo', geoCopy.geoTarget.geoTargetId)

          return
        }
      }

      this.$toast(`We ran into a problem saving to your ${this.verbiage.geoTargetLibrary}`, 'danger')
    },
    async selectNamedGeo (e) {
      this.namedGeoSelection = e
      const name = this.availableAccountGeoOptions.find(go => e === go.value)?.text

      if (!name) {
        return
      }

      if (!this.campaignPlanId) {
        this.createFlowGeoAssetPlaceholderId = e
        this.$emit('setCreateFlowGeoAssetPlaceholderId', e)
        this.$emit('linkToGeo', e)
        return
      }

      const conf = await this.$confirm({
        text: `This will force this Campaign to use values set at the account level in ${this.verbiage.geoTargetItem}: "${name}"`,
        'ok-title': `Yes, use this ${this.verbiage.geoTargetItem}`
      })

      if (conf) {
        this.$emit('linkToGeo', e)
      }
    },
    async checkForExclusions () {
      if (this.isAccount && !this.determineEligibility) {
        this.determineEligibility = await this.$res.account.determineGeoEligibility(this.geoTargetDTO)
      }
    },
    buildIneligibleList (listName) {
      if (this.isAccount && this.determineEligibility) {
        let returnString = ''
        const list = this.determineEligibility?.[listName]
        for (let i = 0; i < list.length; i++) {
          const channelLabel = this.advertisingChannels.find(ch => parseInt(list[i]) === ch.advertisingChannelId)?.channelLabel || ''
          returnString += channelLabel

          if (channelLabel && list.length > 1) {
            if (list.length === 2) {
              if (i === 0) {
                returnString += ' and '
              }
            } else if (i === list.length - 2) {
              returnString += ', and '
            } else if (i !== list.length - 1) {
              returnString += ', '
            }
          }
        }
        return returnString
      }

      return null
    },
    getCsvData () {
      const combinedGeos = []
      this.geoTargetDTO.includedGeoCodeTargets.forEach(target => {
        combinedGeos.push({
          type: 'Included Location',
          canonicalName: target.googleCanonicalName,
          bidModifier: target.bidModifier,
          lat: '',
          lng: '',
          radius: ''
        })
      })
      this.geoTargetDTO.includedRadiusTargets.forEach(target => {
        combinedGeos.push({
          type: 'Included Radius',
          canonicalName: '',
          bidModifier: target.bidModifier,
          lat: target.itemCentroidLat,
          lng: target.itemCentroidLng,
          radius: target.radius
        })
      })
      this.geoTargetDTO.excludedGeoCodeTargets.forEach(target => {
        combinedGeos.push({
          type: 'Excluded Location',
          canonicalName: target.googleCanonicalName,
          bidModifier: target.bidModifier,
          lat: '',
          lng: '',
          radius: ''
        })
      })
      this.geoTargetDTO.excludedRadiusTargets.forEach(target => {
        combinedGeos.push({
          type: 'Excluded Radius',
          canonicalName: '',
          bidModifier: target.bidModifier,
          lat: target.itemCentroidLat,
          lng: target.itemCentroidLng,
          radius: target.radius
        })
      })
      return Papa.unparse(combinedGeos)
    },
    downloadGeoTargetCSV () {
      const csvContent = this.getCsvData()
      if (!csvContent) return
      let csvData = new Blob([csvContent], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const link = document.createElement('a')
      link.setAttribute('href', csvUrl)
      link.setAttribute('download', 'geoTarget_export.csv')
      link.click()

      link.remove()
      URL.revokeObjectURL(csvUrl)
      csvData = null
    }
  }
}
</script>

<style lang='scss' scoped>
//.active-underspend {
//  background-color: $fluency-lightyellow;
//}

.location-box {
  overflow-y: auto;
  max-height: 21.5em;

  .active-underspend {
    background-color: $fluency-lightyellow;
  }

  & > div:nth-child(even):not(.active-underspend) {
    background: $fluency-lightergray
  }
}

.location-card {
  .card-body {
    padding: 0;
  }

  .card-header {
    padding: 0.25em;
  }
}

.geoLocation:hover {
  background-color: #eee;
}

.selected-radius {
  background-color: $fluency-lightblue;
}

.targeting-legend:before {
  content: ' ';
  display: inline-flex;
  margin-right: .25rem;
  width: 1rem;
  height: 1rem;
  border: $fluency-yellow 1px solid;
  background-color: $fluency-lightyellow;
}

</style>
